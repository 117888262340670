import React, { useState } from 'react';
import moment from 'moment';
import { Container, Row, Col } from 'react-grid-system';
import { useIntl } from 'gatsby-plugin-intl';
import Img from 'gatsby-image';

import Alert from '../components/Alert/Alert';
import ArticleTile from '../components/ArticleTile/ArticleTile';
import Layout from '../components/Layouts/layout';
import SEO from '../components/seo';
import PaginationNav from '../components/PaginationNav/PaginationNav';

import { getPaginatedResults } from '../utils/sorting';

const BlogPage = ({ data }) => {
	const intl = useIntl();
	const [currentPage, setPage] = useState(1);

	const blogArr = data.blogPosts.edges;

	return (
		<Layout
			header={
				<>
					HCRA Blog <span className='bold'>The Home Front</span>
				</>
			}
		>
			<SEO lang='en' title='The Home Front' />
			<Container>
				{intl.locale === 'fr' && (
					<Row>
						<Col>
							<Alert header='Attention'>
								<p lang='fr'>
									Pour les services en français, veuillez contacter le Service des licences et à la
									clientèle de l’Office de réglementation de la construction des logements au
									416-487-HCRA (4272) ou <a href='mailto:info@hcraontario.ca'>info@hcraontario.ca</a>
								</p>
							</Alert>
						</Col>
					</Row>
				)}
				<Row>
					{getPaginatedResults(blogArr, currentPage).map((post, i) => (
						<Col md={6}>
							<ArticleTile
								id={post.node.frontmatter.slug}
								image={<Img fluid={post.node.frontmatter.image.childImageSharp.fluid} alt='' />}
								title={post.node.frontmatter.title}
								linkPath={`/blog/${post.node.frontmatter.slug}`}
								buttonTxt='Read Post'
							>
								<div style={{ marginBottom: `.5rem` }}>
									<span class='bold'>
										{moment(post.node.frontmatter.date).format('MMMM Do, YYYY')}
									</span>
								</div>
								<h2 className='underline'>{post.node.frontmatter.title}</h2>
								<p>{post.node.frontmatter.excerpt}</p>
							</ArticleTile>
						</Col>
					))}
				</Row>

				{blogArr.length > 10 && (
					<Row>
						<Col>
							<PaginationNav
								currentPage={currentPage}
								clickEvt={setPage}
								resultsLength={blogArr.length}
							/>
						</Col>
					</Row>
				)}
			</Container>
		</Layout>
	);
};
export default BlogPage;

export const pageQuery = graphql`
	query BlogPagesQuery {
		blogPosts: allMarkdownRemark(
			filter: { fileAbsolutePath: { regex: "/blog/" } }
			sort: { fields: frontmatter___date, order: DESC }
		) {
			edges {
				node {
					id
					frontmatter {
						slug
						title
						date
						excerpt
						image {
							absolutePath
							childImageSharp {
								fluid {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
				}
			}
		}
	}
`;
