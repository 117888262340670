import React from 'react';
import componentStyles from './ArticleTile.module.scss';
import Button from '../Button/Button';

const ArticleTile = ({ id, image, linkPath, title, buttonTxt, disabled, ...props }) => {
	return (
		<div className={componentStyles.tile}>
			<div className={componentStyles.image}>{image}</div>

			<div className={componentStyles.content}>
				<div className={componentStyles.contentContainer}>
					<div>{props.children}</div>
					{!disabled && (
						<Button
							id={id}
							aria-label={`Read: ${title}`}
							linkPath={linkPath}
							text={buttonTxt ? buttonTxt : 'Read'}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default ArticleTile;
